<template>
  <div>
    <el-row>
      <el-col :span="20">{{ $t('order.Orderinformation') }}</el-col>
    </el-row>
    <hr>

    <el-form ref="orderForm" :model="orderForm" :rules="rules" label-width="140px">
      <el-row :span="24">
        <el-col :span="6">
          <el-form-item :class="$i18n.locale" :label="$t('title.ordernumber')">
            <el-input v-model="orderForm.orderNumber" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :class="$i18n.locale" :label="$t('order.Sourcenumber')">
            <el-input v-model="orderForm.purchaseOrderNumber" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.types')" prop="orderType">
            <el-select v-model="orderForm.orderType" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option :label="$t('order.Replacement')" value="9" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item :label="$t('page.status')">
            <el-select v-model="orderForm.orderStatus" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option :label="$t('order.Cancel')" value="0" />
              <el-option :label="$t('order.Awaitingreview')" value="1" />
              <el-option :label="$t('order.Systemreviewed')" value="2" />
              <el-option :label="$t('order.Shipped')" value="3" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.platform')" prop="platform">
            <el-input v-model="orderForm.platform" :placeholder="$t('page.inputPlaceholder')" disabled />
            <!-- <el-select v-model="form.platform" clearable filterable placeholder="平台" disabled>
              <el-option
                v-for="item in platforoptions"
                :key="item.id"
                :label="item.platformCode"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.site')" prop="site">
            <!-- <el-input v-model="orderForm.site" :placeholder="$t('page.inputPlaceholder')" disabled /> -->
            <el-select v-model="orderForm.site" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
              <el-option
                v-for="item in siteoptions"
                :key="item.id"
                :label="item.siteCode"
                :value="item.siteCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 店铺 -->
        <el-col :span="6">
          <el-form-item :label="$t('order.Sellingaccount')" prop="merchant">
            <!-- <el-input v-model="orderForm.merchant" :placeholder="$t('page.inputPlaceholder')" /> -->
            <el-select v-model="orderForm.merchant" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
              <el-option
                v-for="item in shopoptions"
                :key="item.shopId"
                :label="item.shopCode"
                :value="item.shopCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :class="$i18n.locale" :label="$t('order.Shippingnotes')">
            <el-input v-model="orderForm.remark" :placeholder="$t('page.inputPlaceholder')" type="textarea" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="20">{{ $t('title.CustomerInfo') }}</el-col>
    </el-row>
    <hr>
    <el-form ref="orderForm" :model="orderForm" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="6">
          <el-form-item :label="$t('order.Receipt')" prop="receiveName">
            <el-input v-model="orderForm.receiveName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Country')" prop="countryCode">
            <el-input v-model="orderForm.countryCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Statesd')" prop="stateRegion">
            <el-input v-model="orderForm.stateRegion" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.city')" prop="city">
            <el-input v-model="orderForm.city" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Address')" prop="addressLine1">
            <el-input v-model="orderForm.addressLine1" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.address')">
            <el-input v-model="orderForm.addressLine2" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Zipcode')" prop="postalCode">
            <el-input v-model="orderForm.postalCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.phone')" prop="receivePhone">
            <el-input v-model="orderForm.receivePhone" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="email">
            <el-input v-model="orderForm.buyerEmail" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 添加SKU -->
    <el-row>
      <el-col :span="6">
        <span>{{ $t('order.Orderinformations') }}</span>
        <el-button class="el-icon-plus" style="margin-left:15px" type="text" @click="showDialog = true">{{ $t('order.AddSUK') }}</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="orderData"
      :header-cell-style="{background:'#fafafa'}"
      :row-class-name="tableRowClassName"
      align="left"
      class="mb-3"
      max-height="600px"
      @cell-dblclick="tabClick"
    >
      <el-table-column
        :label="$t('page.No')"
        align="center"
        min-width="70"
        type="index"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="SKU" min-width="300" prop="sku">
        <template slot-scope="scope">
          <div> {{ scope.row.sku ? scope.row.sku:'' }} </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('order.Buyerinformation')" align="center" min-width="200" prop="sku">
        <template slot-scope="scope">
          <div> {{ scope.row.styleName ? scope.row.styleName : scope.row.style ? scope.row.style:'' +'/' }} {{ scope.row.color ? scope.row.color:'' +'/' }} {{ scope.row.size ? scope.row.size:scope.row.sizes }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('order.Quantityenter')" align="center" min-width="100" prop="address">
        <template slot-scope="scope">
          <span v-if="scope.row.index === tabClickIndex && (tabClickLabel === '数量(双击输入数量)'|| tabClickLabel === 'Quantity(Double click to enter)')">
            <el-input
              v-model="scope.row.itemQuantity"
              :placeholder="$t('page.inputPlaceholder')"
              maxlength="300"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              size="mini"
              @blur="inputBlur"
            />
          </span>
          <span v-else>{{ scope.row.itemQuantity }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('page.operate')"
        align="center"
        fixed="right"
        min-width="100"
      >
        <template slot-scope="scope">
          <!-- 删除 -->
          <span>
            <el-button size="small" type="text" @click="handleDelete(scope.$index, scope.row)">{{ $t('page.delete') }}</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-row :span="24" class="mt-3" style="text-align: center">
      <el-button :loading="submitLoading" type="primary" @click="submitOrderData">{{ $t('title.submit') }}</el-button>
    </el-row>
    <!-- 添加sku -->
    <el-dialog :title="$t('order.AddSUK')" :visible.sync="showDialog" width="90%">
      <el-form
        ref="form"
        :model="form"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-row :span="24">
          <el-col :span="4">
            <el-form-item label="BU">
              <el-select v-model="form.buId" clearable filterable value-key="id" @change="BUSelectChange">
                <el-option
                  v-for="item in BuOptions"
                  :key="item.id"
                  :label="item.buName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Style">
              <el-select v-model="form.styleId" clearable filterable value-key="id" @change="styleSelectChange">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Color">
              <el-select v-model="form.colorId" clearable filterable>
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.id"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Size">
              <el-select v-model="form.sizeId" clearable filterable>
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="SKU">
              <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-button type="primary" @click="handleQuery">{{ $t('page.search') }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="skuDatas" :row-class-name="tableRowClassName" @cell-dblclick="tabClick">
        <el-table-column align="center" label="SKU" property="sku" />
        <el-table-column :label="$t('order.Buyerinformation')" align="center" property="sku">
          <template slot-scope="scope">
            <div> {{ scope.row.styleName +'/' }} {{ scope.row.color +'/' }} {{ scope.row.size }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('order.Quantityenter')" align="center" property="address">
          <template slot-scope="scope">
            <span v-if="scope.row.index === tabClickIndex && (tabClickLabel === '数量(双击输入数量)'|| tabClickLabel === 'Quantity(Double click to enter)')">
              <el-input
                v-model="scope.row.itemQuantity"
                :placeholder="$t('page.inputPlaceholder')"
                maxlength="300"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                size="mini"
                @blur="inputBlur"
              />
            </span>
            <span v-else>{{ scope.row.itemQuantity }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-row :span="24" class="mt-3 pb-3" style="text-align: center">
        <el-button @click="showDialog = false">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" @click="submitSkuData">{{ $t('title.submit') }}</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import {
  getSkuByParams,
  orderInfoDetail,
  queryBaseBuList,
  queryColorList,
  queryShopList,
  querySiteList,
  querySizeList,
  queryStyleList,
  queryTerraceList,
  saveOrUpdate
} from '@/api/omsorder'

export default {
  mixins: [mixin],
  data() {
    return {
      submitLoading: false,
      platforoptions: [],
      siteoptions: [],
      shopoptions: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      showDialog: false,
      skuDatas: [],
      orderData: [],
      orderForm: {
        orderType: '3',
        orderStatus: '1'
      },
      pager: {
        current: 1,
        size: 800,
        total: 0
      },
      form: {
        styleId: '',
        colorId: '',
        sizeId: ''
      },
      tabClickIndex: '',
      tabClickLabel: '',
      BuOptions: '',
      rules: {
        orderType: [{ required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] }],
        platform: [{ required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] }],
        site: [{ required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] }],
        merchant: [{ required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] }],
        receiveName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        countryCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        stateRegion: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        city: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        addressLine1: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        postalCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        receivePhone: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }]

      }
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      return Object.assign({}, this.pager, this.form)
    }
  },
  watch: {
    'orderForm.site': {
      handler(val) {
        this.shopoptions = []
        if (val) {
          const siteId = this.siteoptions.find((item) => item.siteCode === val)?.id
          this._queryShopList(siteId)
        }
      }
    }
  },
  async mounted() {
    this._queryStyleList()
    this._queryColorList()
    this._querySizeList()
    this._queryTerraceList()
    this._queryBaseBuList()
    await this._querySiteList()
    await this._orderInfoDetail()
  },
  methods: {
    async _querySiteList() {
      // 传了没用
      this.platformId = '18'
      const { datas } = await querySiteList(this.platformId)
      this.siteoptions = datas
      this.siteoptions = this.siteoptions.filter((item) => {
        return item.siteName === 'US' || item.siteName === 'CA' || item.siteName === 'GB' || item.siteName === 'EU' || item.siteName === 'CN'
      })
    },
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platforoptions = datas
    },
    async _queryShopList(siteId) {
      // 默认查KOL，对应id为17
      const { datas } = await queryShopList({ siteId: siteId, sellPlatformId: 17 })
      this.shopoptions = datas
      this.orderForm.merchant = datas?.[0]?.shopCode
    },
    // 通过id 查询对应数据
    async _orderInfoDetail() {
      const { id, orderCreateUtcDate } = this.$route.query
      if (id) {
        const { data: datas } = await orderInfoDetail({ id, createUtcTime: orderCreateUtcDate })
        this.datasObj = datas
        this.orderData = datas.items || []
        // platform + '-' + site + '-' + merchant
        const { orderNumber, receiveName, countryCode, stateRegion, city, addressLine1, addressLine2, postalCode, receivePhone, buyerEmail } = datas
        this.orderForm = { platform: 'KOL', site: (datas.site === 'US' || datas.site === 'CA' || datas.site === 'GB') ? datas.site : '', orderStatus: '1', purchaseOrderNumber: orderNumber, orderType: '9', merchant: 'topshoesUS', receiveName, countryCode, stateRegion, city, addressLine1, addressLine2, postalCode, receivePhone, buyerEmail }
        this.orderForm.remark = datas.platform + ' - ' + datas.site + ' - ' + datas.merchant
      }
    },
    BUSelectChange(value) {
      // const data = this.BuOptions.find(item => item.id === value)
      this._queryStyleList(value)
    },
    async _queryStyleList(id) {
      const { datas } = await queryStyleList({ buId: id })
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      console.log(value)
      const data = this.styleOptions.find(item => item.id === value)
      console.log(data)
      this._queryColorList(data.id)
      this._querySizeList(data.id)
      this.form.colorId = ''
      this.form.sizeId = ''
      // this.stockManageForm.style = this.stockManageForm.style.styleName
    },
    async _queryBaseBuList() {
      const { datas } = await queryBaseBuList()
      this.BuOptions = datas
    },

    handleQuery() {
      this._getSkuByParams(this.queryParameter)
    },
    async _getSkuByParams(params) {
      const { datas } = await getSkuByParams(params)
      this.skuDatas = datas.records
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },

    // table点击 row 当前行 column 当前列
    tabClick(row, column, cell, event) {
      this.tabClickIndex = row.index
      this.tabClickLabel = column.label
    },
    // 失去焦点初始化
    inputBlur(row, event, column) {
      this.tabClickIndex = null
      this.tabClickLabel = ''
    },
    tableRowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row
      row.index = rowIndex
    },

    // 提交sku至明细中
    submitSkuData() {
      if (this.skuDatas.length >= 0) {
        this.skuDatas.map(e => {
          var index = this.orderData.findIndex((i) => {
            return e.sku === i.sku
          })
          if (index === -1) {
            this.orderData.push(e)
          }
        })
      }
      // this.orderData = this.skuDatas
      this.showDialog = false
    },
    handleDelete(index) { // 删除行数
      this.orderData.splice(index, 1)
    },
    // 确认提交手工建单
    submitOrderData() {
      this.$refs.orderForm.validate((valid) => {
        if (!valid) return false

        const parameter = Object.assign({}, this.orderForm)
        console.log(parameter)
        if (this.orderForm.countryCode === 'US') {
          parameter.currency = 'USD'
        } else if (this.orderForm.countryCode === 'CA') {
          parameter.currency = 'CAD'
        } else if (this.orderForm.countryCode === 'UK') {
          parameter.currency = 'GBP'
        } else if (this.orderForm.countryCode === 'GB') {
          parameter.currency = 'GBP'
        } else if (this.orderForm.countryCode === 'DE') {
          parameter.currency = 'DEM'
        } else if (this.orderForm.countryCode === 'FR') {
          parameter.currency = 'FRF'
        } else {
          parameter.currency = 'USD'
        }
        parameter.items = this.orderData
        this._saveOrUpdate(parameter)
      })
    },
    async _saveOrUpdate(datas) {
      try {
        this.submitLoading = true
        const { responseDesc, success } = await saveOrUpdate(datas)
        const { source } = this.$route.query
        if (success) {
          this.$notify({
            title: responseDesc,
            message: responseDesc,
            type: 'success'
          })
          // 来自售后
          if (source === 'AfterSales') {
            this.$router.push({ path: '/order-manage/order-center-manage' })
          } else {
            this.$router.back()
          }
        }
      } catch (error) {
        this.submitLoading = false
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-upload--picture-card {
    width: 75px;
    height: 75px;
    cursor: pointer;
    line-height:75px;
}
.hide .el-upload--picture-card {
  display: none;
}
</style>
